<template>
  <div>
    <v-container class="container px-0">
      <v-row
        class="title-row"
        :class="`${
          $vuetify.breakpoint.mdAndDown ? 'flex-column flex-nowrap' : ''
        }`"
      >
        <v-col class="d-flex align-center">
          <h4 class="font-weight-light">{{ $t('heading.hosting.fileManager.title') }}</h4>
          <active-server-account-selector
            v-if="service && service.server_accounts.length > 1"
            class="ml-4"
            :value.sync="serverAccount"
            :items="service.server_accounts"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-3 px-0">
      <v-row>
        <v-col cols="12">
          <iframe
            v-if="serverAccount && !serverAccount.diagnostic_mode"
            style="width: 100%; height: 700px;"
            :src="apiUrl"
            allow="fullscreen"
            class="file-manager"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
//import Api from "../../apis/Api";
import ActiveServerAccountSelector from "@/components/ActiveServerAccountSelector.vue";

export default {
  components: {
    ActiveServerAccountSelector,
  },
  data() {
    return {
      isServerProvided: false,
      loading: true,
      addButtonLoading: false,
      items: [],
      searchTerm: "",
      sortDesc: true,
      page: 1,
      itemsPerPage: 8,
      modalRender: 0,
      modalOptions: {open: false},
      highlightItem: {},
      serverAccount: null
    };
  },
  props: {
    service: Object
  },
  computed: {
    apiUrl() {
      let url = process.env.VUE_APP_FILE_MANAGER_DEV || `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ``}/fm/`;
      if (this.serverAccount) {
        return `${url}?id=${this.serverAccount.id}`;
      }
      return url;
    }
  },
  watch: {
    service: function () {
      this.serverAccount = this.service.server_accounts[0];
    },
    serverAccount: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.serverAccount.diagnostic_mode) {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.$t("message.hostingAccountInDiagnosticMode"),
          });
        }
      }
    },
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
  mounted() {
    if (this.service) {
      this.serverAccount = this.service.server_accounts[0];
    }
  },
};
</script>

<style scoped lang="scss">
.title-row {
  @media (min-width: 1401px) {
    height: 70px;
  }
}
</style>
